/* General page layout */
.enquiry-page {
  display: flex;
  background-color: #f5f7fa;
  min-height: 100vh; /* Full viewport height */
}

/* Side Menu */
.side-menu {
  width: 220px;
  height: auto;
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.side-menu h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

/* Side Menu Logo and Text */
.logo-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  width: 40px; /* Adjust the width of the image as needed */
  height: auto;
  margin-right: 10px; /* Space between the image and text */
}

.logo-text {
  font-size: 18px; /* Adjust the font size as needed */
  color: white;
}

/* Navigation Items */
.side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-menu ul li {
  margin-bottom: 15px;
}

.side-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: block;
  display: flex;
  align-items: center; /* Align text and icons vertically */
}

.side-menu ul li a:hover {
  color: #00aaff;
}

.side-menu ul li a .icon {
  margin-right: 10px; /* Space between icon and text */
  width: 20px; /* Adjust the icon size as needed */
  height: auto; /* Maintain aspect ratio */
}


/* Top Head */
.top-head {
  width: 100%;
  height: 60px;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px 40px;
}

/* Table container */
.table-container {
  margin-top: 20px;
  background: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

table th {
  background-color: #007bff;
  color: white;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

/* Pagination Controls */
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}

.pagination-btn:hover {
  background-color: #0056b3;
}

.pagination span {
  font-size: 18px;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 90%;
}

.close {
  float: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: red;
}

/* Responsive Design */
@media (max-width: 768px) {
  .side-menu {
      width: 180px;
  }

  .main-content {
      margin-left: 180px;
  }

  table th, table td {
      padding: 8px;
  }
}

@media (max-width: 576px) {
  .side-menu {
      width: 100%;
      height: auto;
      position: relative;
  }

  .main-content {
      margin-left: 0;
      padding: 10px;
  }

  .table-container {
      padding: 10px;
  }

  .modal-content {
      width: 90%;
  }
}

/* Login Page Styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-box h1 {
  margin-bottom: 1.5rem;
  font-size: 24px;
  color: #333;
}

.login-box input {
  margin-bottom: 1rem;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: calc(100% - 16px);
}

.login-box input:focus {
  border-color: #007bff;
  outline: none;
}

.login-box button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.login-box button:hover {
  background-color: #0056b3;
}

.login-box .error {
  color: #e74c3c;
  margin-bottom: 1rem;
}

/* Additional Styles for Enquiry Page Details */
.details-container {
  background-color: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
  width: auto; /* Full width to stretch across the screen */
  max-width: none; /* Remove max-width to allow full stretch */
  margin-right: 58px; /* Margin on top and bottom only */
  margin-top: 10px;
  font-family: 'Arial', sans-serif;
}

.details-header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.details-info p {
  font-size: 16px;
  margin-bottom: 10px;
}

.details-info strong {
  font-weight: bold;
  color: #222; /* Darker text for strong emphasis */
}

.details-info {
  line-height: 1.6;
}

.details-button {
  text-align: center;
  margin-top: 20px;
}

.details-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-btn:hover {
  background-color: #0056b3;
}
