* {
    padding: 0px;
    margin: 0px;
}

.car-background {
    background-image: url('../src/assets/images/carpicture.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.continuehometext{
    color: #ffffff;
}
.maindiv{
    background-color: #f9f9f9;
}

.fnameError {
    color: #ff0000;
}

.carmodaltext {
    color: #ff0000;
    margin-Top: 12px
}

.firsttext {
    font-weight: bold;
    width: 74%;
    margin-left: 13%;
    margin-top: -4%;
}

.maincontainer {
    width: 100%;
    align-self: center;
}

.mobileinputmaindiv {
    width: 100%;
    display: flex;
    border: 0.5px solid #969696;
}

.dropdowncountry {
    width: 14%;
    background-color: #fff;
    height: 40px;
}

.mobileinputdiv {
    width: 86%
}

.mobileinput {
    width: 98%;
    height: 40px;
    padding-left: 10px;
    font-size: 18px;
    border: none;
    outline: none;
    border-left: 0.5px solid #969696;
    margin-left: -0.7%;
}

.css-1u9des2-indicatorSeparator {
    width: 0px !important;
}

.flagmaindiv {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    height: 34px;
    border-radius: 0px;
}

.flag {
    width: 24px;
    margin-right: 10px
}

.css-vbtasw-control {
    border-radius: 0px !important;
    height: 39px !important;
    margin-top: 0px;
    width: 95% !important;
}

.logodiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
}

.services {
    display: flex;
    width: 76%;
    justify-content: space-around;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-left: 12%;
    margin-right: 12%;
    margin-top: 8%;
}

.services2 {
    display: flex;
    width: 76%;
    justify-content: space-around;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-left: 12%;
    margin-right: 12%;
    margin-top: 3%;
}

.services3 {
    display: flex;
    width: 76%;
    justify-content: space-around;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-left: 12%;
    margin-right: 12%;
    margin-top: 2%;
}

.heading-img {
    text-align: center;
    font-size: 30px;
    color: #fff;
}

.input-div-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin-top: 0%;
    background-color: #fff;
    left: 100%;
    align-self: center;
    padding-bottom: 2%;
    box-shadow: rgba(0, 0, 0, 0.16)0px1px4px;
}

.paragraph-img {
    width: 100%;
    margin-top: -6px;
}

.heading {
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
    font-weight: bold;
}

.inservicesdiv {
    width: 27%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.heading-text2 {
    font-size: 13px;
    text-align: left;
    margin-top: 5px;
}

.seconddiv {
    width: 93%;
    display: flex;
    gap: 100px;
    justify-content: space-between;
    margin-top: 30px;
    padding: 50px;
}

.first-heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.first-heading2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.firstdiv {
    width: 50%;
}

.anotherdiv {
    width: 50%;
}

.heading-text {
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
}

.seconddiv-text {
    font-size: 13px;
    margin-top: 15px;
}

.second-div-text {
    font-size: 14px;
    margin-top: 5px;
}

.footerp {
    font-size: 13px;
    margin-top: 10px;
}

.footerfirstdiv {
    width: 50%;
    padding-left: 30px;
}

.customersdiv {
    background-color: #fff;
    width: 96%;
    margin-top: 2%;
    padding-bottom: 20px;
    margin-left: 2%;
}

.optionHeading {
    padding-top: 20px;
}

.option-heading-Text {
    font-size: 25px;
    text-align: center;
    font-weight: bold;
}

.icon {
    width: 35px;
    height: 35px;
}

.image-div {
    display: flex;
}

.image {
    width: 100px;
    height: 100px;
    margin-left: 10%;
    margin-top: 2%;
}

.firstimage {
    width: 100px;
    height: 100px;
    margin-left: 0%;
    margin-top: 2%;
}

.carpicture {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.carpicturepage {
    width: 100%;
    height: 170px;
}

.carpicturediv {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
}

.logo1 {
    width: 180px;
    height: 80px;
}

.yellowdiv {
    background-color: #EEDC3B;
    height: 20px;
    width: 100%;
    align-self: center;
}

.image-text-div {
    position: absolute;
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: -80px;
    justify-content: space-between;
    height: 100%;
}

.paragraph-img-text {
    font-size: 25px;
    text-align: center;
    color: #fff;
    width: 80%;
    margin-left: 10%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footerdiv {
    width: 88%;
    display: flex;
    justify-content: space-between;
    margin-left: 6%;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footerheading {
    font-size: medium;
    margin-top: 25px;
    font-weight: bold;
    text-align: center
}

.enterVehicle {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0px;
}

.input {
    width: 100%;
    background-color: #EEDC3B;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.mapInput {
    display: flex;
    width: 90%;
    margin-top: 2%;
    margin-bottom: 1%;
    height: 50px;
}

.nextbutton {
    width: 48%;
    background-color: #18624D;
    border: none;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
}

.previewbutton {
    width: 48%;
    background-color: #969696;
    border: none;
    margin-top: 30px;
    height: 40px;
    border-radius: 5px;
}

.bothbuttondiv {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
}

.continuetext {
    font-size: 15px;
    color: #fff;
    margin-top: 8px;
}

.container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
}

.main-div-register {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-self: center;
    height: 80px;
    background-color: #EEDC3B;
    margin-top: 10px;
}

.mapdiv2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #0054AD;
    width: 10%;
    align-items: center;
    padding-top: 10px;
}

.map2 {
    width: 80%;
    height: 40%;
}

.GB2 {
    font-size: 26px;
    text-align: center;
    color: #fff;
}

.carnumber {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.carnumbertext {
    color: black;
    font-weight: bold;
    font-size: 30px;
    margin-top: 5px;
}

.modaldiv {
    width: 90%;
    background-color: #007bfc;
}

.seconddiv2 {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.arrowdiv {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    background-color: #F9F9F9;
    margin-top: 30px;
    padding-left: 10px;
}

.arrowdiv2 {
    display: flex;
    flex-direction: row;
    height: 50px;
    align-items: center;
    background-color: #F9F9F9;
    margin-top: 30px;
    padding-left: 10px;
}

.arrowlogo {
    width: 20px;
    margin-right: 20px;
}

.arrowtext {
    font-weight: bold;
    margin-top: 0px;
}

.inputyear {
    border: none;
    outline: none;
    font-size: 20px;
}

.inputerror_error_text {
    margin-bottom: 15px;
}

.inputyeardiv {
    margin-top: 20px;
    padding-left: 50px;
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 50px;
    margin-top: 20px;
}

.custom-radio input[type="radio"] {
    display: none;
}

.custom-radio {
    position: relative;
    padding-left: 5%;
    margin-right: 15px;
    cursor: pointer;
    font-size: 22px;
    display: inline-flex;
    align-items: center;
}

.custom-radio::before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 15px;
    height: 15px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
}

.custom-radio.selected::before {
    border-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-radio.selected::after {
    content: "";
    position: absolute;
    left: 1.5px;
    top: 4.5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #EEDC3B;
}

.radio-text {
    font-size: 16px;
    color: #333;
    margin-left: 20px;
}

.buttoncontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.continuebutton {
    background-color: #18624D;
    width: 100%;
    border: none;
    height: 40px;
    border-radius: 5px;
    margin-top: 20px;
}

.detailMainContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    padding-top: 15px;
    padding: 3%;
}

.detailcontainer {
    height: 50px;
    display: flex;
    background-color: #f9f9f9;
    align-items: center;
}

.detailsText {
    font-size: 18px;
    font-weight: 600;
    margin-top: 2px;
    margin-left: 10px;
}

.detailsText2 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
    margin-left: 10px;
}

.personimg {
    width: 35px;
    height: 25px;
    margin-left: 15px;
    margin-right: 5px;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #EEDC3B;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
    justify-content: center;
    margin-left: 50%;
}

.ContinueButton {
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
    width: 90%;
    background-color: #18624D;
}

.homepersonimg {
    width: 30px;
    height: 27px;
    margin-top: 0px;
    margin-bottom: -4px;
}

.underline-amount {
    text-decoration: underline;
    text-decoration-color: #EEDC3B;
}

.good-news-div {
    margin-top: 40px;
}

.three-input {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.inputsdiv {
    width: 30%;
    display: flex;
    flex-direction: column;
}

.title-Input {
    height: 40px;
    padding-left: 10px;
    font-size: 18px;
    outline: none;
}

.title-Input2 {
    height: 40px;
    padding-left: 10px;
    font-size: 18px;
    outline: none;
}

.dropdown {
    height: 43px;
    padding-left: 10px;
    font-size: 18px;
    outline: none;
}

.street-Input {
    width: 98%;
    height: 40px;
    font-size: 18px;
    outline: none;
    padding-left: 1%;
}

.mobile-Input-div {
    width: 100%;
    height: 40px;
    font-size: 18px;
    outline: none;
    margin-top: 13px;
}

.postcode-Input {
    width: 98%;
    height: 40px;
    font-size: 18px;
    outline: none;
    padding-left: 1%;
}

.postcode-Inputdiv {
    width: 80%;
    height: 40px;
    font-size: 18px;
    outline: none;
}

.name-Input {
    width: 100%;
    padding-left: 10px;
}

.titleText {
    margin-bottom: 10px;
    font-weight: 600;
}

.titleTextlastname {
    margin-bottom: 10px;
    font-weight: 600;
    color: #fff;
}

.titledropdown {
    color: #d3d3d3;
}

.country {
    width: 46%;
    height: 40px;
    outline: none;
}

.mobilediv {
    margin-top: 10px;
}

.addressdiv {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.citypostcodediv {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.adddressSeachtext {
    font-size: 15px;
    font-weight: 500;
}

.findbutton {
    width: 18.5%;
    height: 42px;
    border: none;
    margin-left: 2%;
    background-color: #969696;
    border-radius: 5px;
}

.findtext {
    color: #fff;
    margin-top: 10px;
}

.emaildiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.datediv {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.daydiv {
    height: 45px;
    min-width: 70px;
    text-align: center;
    outline: none;
    margin-right: 1%;
    width: 15%;
}

.email-Input {
    width: 100%;
    height: 40px;
    font-size: 18px;
    outline: none;
}

.cityinput {
    width: 96%;
    height: 40px;
    padding-left: 2%;
    font-size: 18px;
    outline: none;
}

.email-Input-div {
    width: 48%;
    height: 40px;
    font-size: 18px;
    outline: none;
}

.city-Input-div {
    width: 48%;
    height: 40px;
    font-size: 18px;
    outline: none;
    margin-top: 15px;
}

.selectCountry {
    font-size: 18px;
    margin-top: 8px;
}

.finalstepdiv {
    background-color: #C6C6C6;
    height: 30px;
}

.introText {
    color: #B8860B;
    font-size: 20px;
    font-family: initial;
    margin-top: 30px;
}

.introdiv {
    width: 90%;
}

.carmodaldiv {
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
}

.carmodalinput3 {
    height: 44px;
    border: none;
    font-size: 18px;
    outline: none;
    margin-top: -5px;
    width: 100%;
    margin-bottom: -15px;
}

.inputerror {
    width: 47.5%;
    height: 40px;
    font-size: 18px;
    outline: none;
}

.required-star {
    color: #ff0000;
    font-weight: bold;
    margin-left: 0.2em;
}

.conformemail-Input {
    width: 96%;
    height: 40px;
    padding-left: 2%;
    font-size: 18px;
    outline: none;
}

.addresssearchdiv {
    margin-top: 10px;
    margin-bottom: -10px;
}

.firstnameText {
    width: 100%;
}

.progress-container {
    width: 100%;
    margin: 20px auto;
    text-align: left;
}

.phone-input {
    width: 100%;
}

.step-indicator {
    margin-bottom: 8px;
    color: #6c757d;
    font-size: 14px;
}

.progress-bar {
    width: 100%;
    background-color: #e9ecef;
    border-radius: 20px;
    overflow: hidden;
}

.progress {
    background-color: #007bff;
    height: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
}

.text-container {
    justify-content: center;
    margin-top: 35px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}

@media (max-width:1300px) {
    .text-container {
        margin-top: -60px;

    }

    .services {
        margin-top: 10%;
    }

    .detailsText2 {
        margin-top: 2px;
    }

    .seconddiv {
        width: 90%;
    }

    .paragraph-img {
        width: 100%;
        margin-top: 77px;
    }

    .paragraph-img-text {
        margin-top: -40px;
        font-size: 30px;
    }

    .image-text-div {
        height: 85%;
        bottom: -60px;
    }

    .input-div-home {
        width: 50%;
    }

    .dropdowncountry {
        width: 23%;
    }

    .mobileinput {
        margin-left: -1%;
    }

    .image {
        margin-top: 6%;
        width: 70px;
        height: 70px;
    }

    .firstimage {
        margin-top: 6%;
        width: 70px;
        height: 70px;
    }
}

@media (max-width:1100px) {
    .text-container {
        margin-top: 0px;
    }

    .heading-img {
        margin-top: -72px;
    }

    .paragraph-img-text {
        margin-top: 13%;
        font-size: 35px; 
        width: 90%;
        margin-left: 5%;
       }

    .services {
        margin-top: 11%;
    }

    .paragraph-img {
        margin-top: -21%;
    }

    .dropdowncountry {
        width: 33%;
    }

    .mobileinput {
        width: 97%;
        margin-left: -1.4%;
    }

    .css-hlgwow {
        padding: 2px 5px !important;
    }

    .image-text-div {
        bottom: -16%;
    }

    .input-div-home {
        margin-bottom: 12%;
        width: 70%;
    }

    .image {
        margin-top: 10%;
        height: 60px;
        width: 60px;
    }

    .firstimage {
        margin-top: 10%;
        width: 60px;
        height: 60px;
    }
}

@media (max-width:900px) {
    .image-text-div {
        bottom: 0;
        width: 100%;
    }

    .seconddiv {
        width: 86%;
    }

    .image {
        margin-top: 14%;
        width: 45px;
        height: 45px;
    }

    .firstimage {
        margin-top: 14%;
        width: 45px;
        height: 45px;
    }

    .input-div-home {
        margin-bottom: -7%;
    }


    .heading-img {
        margin-top: 12%;
    }

    .mapdiv2 {
        padding-top: 4px;
    }

    .paragraph-img-text {
        margin-top: 30%;
        font-size: 30px;
        width: 90%;
        margin-left: 5%;
    }
    .paragraph-img {
        margin-top: -32%;
    }

    .dropdowncountry {
        width: 40%;
    }

    .mobileinput {
        margin-left: -1.5%;
    }
    .text-container{
        margin-top: 50px;
    }
}

@media (max-width:745px) {
    .image-text-div {
        bottom: 70px;
    }

    .services {
        margin-top: 20%;
    }
}

@media (max-width:680px) {
    .carmodaldiv {
        flex-direction: column;
        align-items: flex-start;
    }

    .adddressSeachtext {
        margin-bottom: 10px;
    }

    .container {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .mapdiv2 {
        min-width: 55px;
        min-height: 80px;
    }

    .input-div-home {
        width: 70%;
        margin-top: 30%;
    }

    .services {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 12%;
    }

    .services2 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .services3 {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inservicesdiv {
        width: 100%;
        margin-top: 8%;
    }

    .footerdiv {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footerp {
        text-align: center;
    }

    .footerfirstdiv {
        width: 100%;
        padding-left: 0;
    }

    .seconddiv {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .first-heading,
    .heading-text2,
    .first-heading2,
    .second-div-text,
    .seconddiv-text {
        text-align: center;
    }

    .firstdiv,
    .anotherdiv {
        width: 100%;
        margin-bottom: 50px;
    }

    .image-div {
        margin-top: 50px;
        justify-content: space-around;
        margin-left: 1%;
        margin-right: 1%;
    }

    .cityinput {
        width: 97%;
    }

    .detailsText {
        margin-top: 6px;
        margin-left: 5px;
    }

    .detailsText2 {
        margin-top: 0;
        margin-left: 5px;
    }

    .finalstepdiv {
        height: 40px;
    }

    .css-1xwv67i-control {
        width: 98%;
    }

    .introdiv {
        width: 100%;
    }

    .introText {
        text-align: center;
    }

    .firsttext {
        font-weight: bold;
        width: 100%;
        margin-left: 0;
        margin-top: 0;
    }

    .paragraph-img-text {
        width: 100%;
        margin-left: 0;
        margin-top: 0%;
        font-size: 25px
    }

    .paragraph-img {
        margin-top: 20%;
    }

    .image-text-div {
        bottom: 134px;
    }

    .three-input,
    .emaildiv {
        flex-direction: column;
    }

    .inputsdiv,
    .inputerror {
        width: 100%;
    }

    .detailMainContainer {
        width: 93%;
        margin-left: 0;
        margin-right: 0;
    }

    .firstnameText {
        margin-top: 10px;
    }

    .name-Input {
        padding-left: 2%;
        width: 98%;
    }

    .citypostcodediv {
        flex-direction: column;
        gap: 15px;
    }

    .city-Input-div {
        width: 100%;
    }

    .carpicture {
        height: 200px;
    }

    .conformemail-Input {
        width: 98%;
    }

    .carnumbertext {
        font-size: 25px;
    }

    .arrowdiv2 {
        margin-top: 60px;
    }

    .custom-radio::before {
        top: 2px;
    }

    .map2 {
        height: 70px;
    }

    .custom-radio.selected::after {
        left: 1px;
        top: 3px;
    }

    .title-Input2 {
        margin-top: -30px;
        width: 98%;
    }

    .dropdown {
        width: 100%;
        padding-left: 1%;
    }

    .country {
        width: 100%;
    }

    .postcode-Input {
        width: 100%;
    }

    .findbutton {
        width: 30%;
    }

    .addresssearchdiv {
        margin-bottom: -25px;
    }

    .daydiv {
        width: 32%;
    }

    .text-container {
        margin-top: 30px;
    }

    .dropdowncountry {
        width: 33%;
    }

    .heading-img {
        margin-top: 26%;
    }

    .image {
        margin-top: 0%;
    }

    .firstimage {
        margin-top: 0%
    }
}

@media (max-width:500px) {
    .services {
        margin-top: 25%;
    }
}

@media (max-width:480px) {
    .image-text-div {
        bottom: -32px
    }

    .paragraph-img {
        margin-top: -15%
    }

    .inservicesdiv {
        margin-top: 3%
    }

    .input {
        height: 50px
    }

    .firsttext {
        font-size: 18px
    }

    .homepersonimg {
        height: 17px;
        margin-bottom: -2px
    }

    .paragraph-img-text {
        font-size: 25px;
        margin-top: -70px
    }

    .input-div-home {
        margin-bottom: -22px;
        width: 90%;
    }

    .seconddiv2 {
        margin-top: 0%
    }

    .seconddiv-text {
        width: 96%;
        margin-left: 2%
    }

    .seconddiv {
        margin-top: 20px;
        width: 73%
    }

    .arrowdiv2 {
        margin-top: 38px
    }

    .heading-text2 {
        width: 96%;
        margin-left: 2%
    }

    .first-heading2 {
        width: 98%;
        margin-left: 1%
    }

    .mapdiv2 {
        min-height: 74px
    }

    .anotherdiv {
        margin-top: -50px
    }

    .text-container {
        margin-top: 100px
    }

    .heading-img {
        margin-top: 13%
    }

    .title-Input2 {
        width: 97%
    }

    .dropdowncountry {
        width: 56%
    }

    .mobileinput {
        width: 95%;
        margin-left: -2.5%
    }
}

@media (max-width:415px) {
    .seconddiv {
        width: 75%
    }

    .image-text-div {
        bottom: 0px
    }

    .heading-img {
        margin-top: 30%
    }

    .services {
        margin-top: 20%
    }

    .text-container {
        margin-top: 100px
    }

    .input-div-home {
        margin-top: 16%
    }
    .paragraph-img-text {
        font-size: 25px;
        margin-top: 10px;
    }
    .paragraph-img {
        margin-top: -30%;
    }
}

@media (max-width:376px) {
    .heading-img {
        margin-top: 4%
    }

    .services {
        margin-top: 10%
    }

    .image-text-div {
        bottom: -4%
    }

    .input-div-home {
        width: 90%;
        margin-top: 0%
    }

    .inservicesdiv {
        margin-top: 3%
    }

    .firsttext {
        width: 99%
    }

    .paragraph-img {
        margin-top: -20%
    }

    .seconddiv {
        width: 72%
    }

    .paragraph-img-text {
        margin-top: 0px;
        font-size: 20px;
        
    }

    .text-container {
        margin-top: 50px
    }

    .mobileinput {
        width: 95%;
        margin-left: -3.5%
    }

    .mobileinputdiv {
        width: 75%
    }
}

@media (max-width:350px) {
    .image-text-div {
        bottom: -18px
    }

    .paragraph-img-text {
        margin-top: -20px;
        font-size: 18px;
    }

    .services {
        margin-top: 35%
    }

    .image {
        width: 40px;
        height: 40px
    }

    .firstimage {
        width: 40px;
        height: 40px;
    }
}

@media (max-width:300px) {
    .paragraph-img-text {
        margin-top: -3px
    }

    .seconddiv {
        width: 63%
    }

    .dropdowncountry {
        width: 100%
    }

    .mobileinput {
        width: 90%;
        margin-left: -6.5%
    }

    .image {
        width: 30px;
        height: 30px
    }

    .firstimage {
        width: 30px;
        height: 30px;
    }
}